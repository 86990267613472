/* 320++  */
.b-platform {
    &__list {
        margin-bottom: 20px;
    }
    
    &__item {
        width: calc(~"100% / 3");
    }

    &__title {
        display: none;
    }

    &__caption {
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 40px;
    }
}
/* 320++  */
.b-expert {
	&__image {
		height: 185px;
	}

	&__panel {
		padding-top: 50px;
	}

	&__title {
		width: 100%;
		text-align: center;
	}

	&__button-group {
		top: 245px;
	}
}
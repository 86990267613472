/* 320++  */
.b-buy-ticket {
    background-image: url('../images/inhtml/buy-ticket-background-mobile.png');
    background-repeat: repeat;
    background-size: contain;

    &__list {
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    &__item {
        width: 50%;
        padding: 0 5px;
        margin-bottom: 10px;

        &:before {
            display: none;
        }

        &.active {
            .b-buy-ticket__link {
                background-color: #ed1a64;
                .b-buy-ticket__link-group {
                    color: #fff;

                    span {
                        color: #fff;
                    }
                }

                .icon-tickets {
                    color: #fff;
                }

                .text {
                    display: block;
                }
            }
        }
    }

    &__link {
        background-color: #ececec;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 110px;

        .icon-tickets {
            display: block;
        }

        .no-touchevents &:hover,
        .touchevents &:active {
            background-color: #ed1a64;
            .b-buy-ticket__link-group {
                color: #fff;

                span {
                    color: #fff;
                }
            }

            .icon-tickets {
                color: #fff;
            }
        }

        .text {
            text-transform: uppercase;
            color: #fff;
            font-size: 13px;
            line-height: 1;
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            text-align: center;
            font-weight: 700;
        }
    }

    &__link-group {
        display: inline-block;
        text-align: right;
        font-size: 13px;
        line-height: 1;
        font-weight: 500;
        color: #7c7c7d;

        span {
            font-size: 40px;
            line-height: 36px;
        }
    }

    &__title {
        border-bottom: none;
        text-align: left;
    }

    &__info {
        text-align: center;
    }

    &__head {
        text-align: center;
        font-size: 25px;
        line-height: 1;
    }

    &__label {
        display: none;
    }

    &__group {
        margin-bottom: 30px;
    }

    &__title {
        font-size: 54px;
        line-height: 1;
        text-align: center;
    }

    &__info {
        color: #444445;
        font-size: 20px;
        line-height: 1;
    }

    &__tab-pane {
        li {
            padding-left: 0;
            border-bottom: 1px solid #ed1a64;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0;
            padding-bottom: 10px;
            margin-bottom: 10px;

            &:before {
                display: none;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    &__button {
        width: 281px;

        .icon-tickets {
            font-size: 50px;
            margin-right: 15px
        }

        .text {
            width: 207px;
            height: 56px;
            font-size: 14px;
            line-height: 56px
        }
    }
}
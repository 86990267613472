/* 320++  */
.b-info {
    &__icon {
        width: 50px;
    }

    &__text {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0;
    }

    &__panel {
        padding-left: 20px;
    }

    &__title {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    &__item {
        margin-bottom: 20px;
    }

    &__head {
        font-size: 25px;
        line-height: 27px;
        letter-spacing: 2px;
        width: 280px;
    }

    &__group {
        margin-bottom: 35px;
    }
}
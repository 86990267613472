/* 320++  */
.b-footer {
	&__panel-top {
		flex-direction: column;
		align-items: flex-start;
		border-bottom: none;
		padding: 0;
		margin-bottom: 10px;
	}

	&__group {
		margin-bottom: 10px;
	}

	&__phone {
		font-size: 18px;
		line-height: 18px;
	}

	&__social-list {
		display: none;
	}

	&__logo {
		width: 70px;

		&--widht {
			width: 90px;
		}
	}
}
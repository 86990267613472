/* 320++  */
.b-about {
    &__wrapper {
        margin-bottom: 10px;
    }

    &__group-button {
        flex-direction: column;
    }

    &__button {
        &--download {
            margin-right: 0;
            margin-bottom: 50px;
        }

        .icon-download {
            margin-right: 22px;
        }

        .text {
            width: 207px;
        }
    }

    &__icon {
        width: 60px;
    }

    &__bold {
        font-size: 63px;
        line-height: 63px;
    }

    &__day {
        font-size: 19px;
        line-height: 23px;
        padding-left: 11px;

        span {
            font-size: 19px;
        }
    }

    &__info {
        padding-left: 17px;

        &--padding {
            padding-left: 19px;
        }
    }

    &__list {
        max-width: 280px;
    }

    &__item {
        padding: 10px 0;
    }

    &__sign {
        height: 24px;
        width: 27px;
        margin-right: 19px;
        margin-left: 6px;

        &:after,
        &:before {
            height: 8px;
        }
    }

    &__text {
        font-size: 10px;
        letter-spacing: .2px;
        line-height: 18px;

        span {
            font-size: 18px;
        }
    }

    &__number {
        font-size: 12px;
        line-height: 12px;

        .b-about__bold {
            font-size: 52px;
            line-height: 52px;
        }
    }

    &__new {
        font-size: 18px;
        line-height: 18px;

        span {
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0;
        }
    }

    &__link {
        a {
            font-size: 20px;
            line-height: 20px;
        }
    }
}